import {AbstractStore} from "@/lib/store/holder/abstract/AbstractStore";
import {SStoreData} from "@/lib/store/model/abstract/SStoreData";
import {AbstractSubStoreManager} from "@/lib/store/manager/abstract/AbstractSubStoreManager";

export abstract class AbstractStoreManagerBuilder<S extends AbstractStore<D>, D extends SStoreData> extends AbstractSubStoreManager<D>
{
  protected abstract _store: AbstractStore<SStoreData>;

  get store(): S
  {
    return this._store as S;
  }

  public add(entry: D)
  {
    const result = this.validate(entry);

    if (!result.isValid)
      throw "Invalid new store entry of instanceId : " + entry.instanceId;

    this._store.add(result.transformedEntity);

    return result.transformedEntity.instanceId;
  }

  public reset(): void {
    this._store.reset();
  }
}