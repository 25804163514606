import {Module } from "vuex-module-decorators";
import {SFileData} from "@/lib/store/model/file/SFileData";
import {AbstractStore} from "@/lib/store/holder/abstract/AbstractStore";

@Module({
  name: "FilesStore",
  namespaced: true,
  stateFactory: true
})

export class FilesStore<D extends SFileData> extends AbstractStore<D>
{
  get filesData(): D[]
  {
    return this.entries;
  }

  get fileDataOfInstanceId()
  {
    return this.byInstanceId;
  }

  public populate()
  {
    // Get wrapped parent module to access getters
    const base: any = Module({})(AbstractStore);
    // Copy parent getters to child
    Object.assign(FilesStore.getters, base.getters);
    Object.assign(FilesStore.mutations, base.mutations);
  }
}