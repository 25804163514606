import {getModule} from "vuex-module-decorators";
import store from "@/lib/store";
import {
  defaultSegmentationsParams
} from "@/data/annotation/DefaultSegmentationsParams";
import {AbstractStoreManagerBuilder} from "@/lib/store/manager/abstract/AbstractStoreManager";
import {SegmentationTargetTypeParamsStore} from "@/store/Spatial2DAnnotation/holder/SegmentationTargetTypeParamsStore";
import {SSegmentationTargetTypeParams} from "@/store/Spatial2DAnnotation/model/SSegmentationTargetTypeParams";
import {SSegmentedTarget} from "@/store/Spatial2DAnnotation/model/SSegmentedTarget";

export class SegmentationTargetTypeParamsStoreManagerBuilder extends AbstractStoreManagerBuilder<SegmentationTargetTypeParamsStore<SSegmentationTargetTypeParams>, SSegmentationTargetTypeParams>
{
  protected _store: SegmentationTargetTypeParamsStore<SSegmentationTargetTypeParams> = getModule(SegmentationTargetTypeParamsStore, store);

  /**
   * May add the default params (used for menus and dot annotation creation
   */
  constructor()
  {
    super();

    this._store.reset();

    defaultSegmentationsParams.forEach(defaultSegmentedBodyPartDisplayParams =>
    {
      this.add({
        ...defaultSegmentedBodyPartDisplayParams
      });
    })
  }

  public addTypesFromTargets(targets: SSegmentedTarget[])
  {
    const entries = this._store.entries;

    targets.map(target => {
      if (entries.filter(entry => entry.value === target.type).length === 0)
        this._store.add({
          instanceId: AbstractStoreManagerBuilder.createInstanceId(),
          value: target.type,
          colorCode: target.colorCode,
        })
    })
  }
}

export const SegmentationTargetTypeParamsStoreManager = new SegmentationTargetTypeParamsStoreManagerBuilder();