import {Module} from "vuex-module-decorators";
import {SCsvData} from "@/lib/store/model/file/SCsvData";
import {FilesStore} from "@/lib/store/holder/file/FilesStore";

@Module({
  name: "CsvStore",
  namespaced: true,
  stateFactory: true
})
export class CsvStore<T extends SCsvData> extends FilesStore<T>
{
  public populate()
  {
    // Get wrapped parent module to access getters
    const base: any = Module({})(FilesStore);
    // Copy parent getters to child
    Object.assign(CsvStore.getters, base.getters);
    Object.assign(CsvStore.mutations, base.mutations);
  }
}