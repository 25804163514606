<script setup lang="ts">

</script>

<template>
  <router-view></router-view>
</template>

<style lang="scss">

</style>

<style lang="scss" src="./lib/assets/scss/layout.scss"></style>
<style lang="scss" src="./assets/layout.scss"></style>