import {createRouter, createWebHistory} from 'vue-router';
import Segmentation2DView from "@/views/Segmentation2D/Segmentation2DView.vue";

const routes = [
    {
        path: '/',
        name: 'segmentation_2D_view',
        component: Segmentation2DView,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router