import {getModule} from "vuex-module-decorators";
import store from "../..";
import {FilesStore} from "@/lib/store/holder/file/FilesStore";
import {SFileData} from "@/lib/store/model/file/SFileData";
import {AbstractStoreManagerBuilder} from "@/lib/store/manager/abstract/AbstractStoreManager";

export class FilesStoreManagerBuilder<S extends FilesStore<D>, D extends SFileData> extends AbstractStoreManagerBuilder<S, D>
{
  protected _store: FilesStore<SFileData> = getModule(FilesStore, store);
}

export const FilesStoreManager = new FilesStoreManagerBuilder();