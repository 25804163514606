import {
    onElementClonedCallback,
    screenshotDomElementToCanvas
} from "@/lib/common/utilities/DomUtilities";
import {delay} from "@/lib/common/utilities/FlowUtilities";
import JSZip from "jszip";
import {
    BlobFile,
    BlobFileCreationArgumentsHolder,
    createBlobFiles, createCsvBlobFile, createJsonBlobFile, createTextBlobFile
} from "@/lib/common/helpers/type/BlobFile";
import {createDownloadableResource, DownloadableResource} from "@/lib/common/helpers/type/DownloadableResource";
import {overlayCanvasOntoImage} from "@/lib/common/utilities/ImageUtilities";

/**
 * Use this class to make your data downloaded
 */
class DownloadHelper
{
    protected nbDownloadedSuccessively = 0;
    protected downloadWatcher: number|undefined = undefined;

    public async download(downloadableResources: DownloadableResource)
    {
        // avoids getting more than 9 downloads at the same time
        if (this.nbDownloadedSuccessively >= 10)
            await delay(1000);

        if (this.nbDownloadedSuccessively === 0)
        {
            this.downloadWatcher = setTimeout(() => {
                this.downloadWatcher = undefined;
                this.nbDownloadedSuccessively = 0;
            }, 1000) as unknown as number;
        }

        this.nbDownloadedSuccessively++;

        // the actual file downloading
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

        a.href = downloadableResources.url;
        a.download = downloadableResources.filename;

        document.body.appendChild(a);

        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(downloadableResources.url);
    }

    public async downloadBlob(blobFile: BlobFile)
    {
        return this.download(createDownloadableResource(blobFile.blob, blobFile.filename));
    }

    public async downloadFile(file: File, filename?: string)
    {
        return this.download(createDownloadableResource(file, filename ? filename : file.name));
    }

    public async downloadAsText(data: string, filename: string)
    {
        return this.downloadBlob(createTextBlobFile(data, filename));
    }

    public async downloadAsCsv(data: any[][], filename: string)
    {
        return this.downloadBlob(createCsvBlobFile(data, filename));
    }

    public async downloadAsJson(data: any, filename: string)
    {
        return this.downloadBlob(createJsonBlobFile(data, filename));
    }

    public async downloadCanvasAsImage(canvas: HTMLCanvasElement, filename: string)
    {
        return this.download(createDownloadableResource(canvas, filename));
    }

    public async downloadCanvasOverlaidOntoImage(canvas: HTMLCanvasElement, image: HTMLImageElement, filename: string)
    {
        const resultCanvas = await overlayCanvasOntoImage(canvas, image);

        return this.download(createDownloadableResource(resultCanvas, filename));
    }

    public async downloadDomElementAsImage(domElement: HTMLElement, filename: string)
    {
        return screenshotDomElementToCanvas(domElement).then((canvas: HTMLCanvasElement) => {
            this.downloadCanvasAsImage(canvas, filename);
        });
    }

    /**
     * Splashes the overlay onto the image and downloads the result as an image
     * @param image
     * @param overlay
     * @param filename
     * @param onElementCloned See screenshotDomElementToCanvas's doc
     */
    public async downloadOverlaidImage(image: HTMLImageElement, overlay: HTMLElement, filename: string, onElementCloned: onElementClonedCallback|undefined = undefined)
    {
        return screenshotDomElementToCanvas(overlay, onElementCloned).then((canvas: HTMLCanvasElement) => {
            this.downloadCanvasOverlaidOntoImage(canvas, image, filename);
        });
    }

    public async zipAndDownloadBlobs(blobFiles: BlobFile[], folderName: string)
    {
        const _this = this;

        const zip = new JSZip();

        const folder = zip.folder(folderName);

        if (folder === null)
            throw "Cannot create zip folder " + folderName;

        blobFiles.forEach(blobFile => {
            folder.file(blobFile.filename, blobFile.blob);
        })

        return zip.generateAsync({ type: 'blob' }).then(function (blob)
        {
            return _this.downloadBlob(
                {
                blob: blob,
                filename: folderName + ".zip"
            });
        });
    }

    public async zipAndDownloadResources(resources: DownloadableResource[], folderName: string, zipName: string)
    {
        const _this = this;

        const zip = new JSZip();

        const folder = zip.folder(folderName);

        if (folder === null)
            throw "Cannot create zip folder " + folderName;

        for (const key in resources)
        {
            const blob = await fetch(resources[key].url).then(r => r.blob());

            folder.file(resources[key].filename, blob);
        }

        return zip.generateAsync({ type: 'blob' }).then(function (blob)
        {
            return _this.downloadBlob(
                {
                    blob: blob,
                    filename: zipName + ".zip"
                });
        });
    }

    public async zipAndDownload(resources: BlobFileCreationArgumentsHolder[], folderName: string)
    {
        return this.zipAndDownloadBlobs(createBlobFiles(resources), folderName);
    }
}

export default new DownloadHelper();