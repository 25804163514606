<script setup lang="ts">

</script>

<template>
  <footer>
    <div class="footer-content">
      <div class="navbar navbar-expand">
        <div class="navbar-left">
          <slot name="footer-content-left"></slot>
        </div>
        <div class="navbar-center">
          <slot name="footer-content-center"></slot>
        </div>
        <div class="navbar-right">
          <slot name="footer-content-right"></slot>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">

  footer {
    width: 100%;
    background-color: var(--background-light);

    .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 48px;
      padding: 0 40px;

      .navbar-left {
        justify-self: flex-start;
      }

      .navbar-center {
        justify-self: center;
      }

      .navbar-right {
        justify-self: flex-end;
      }

      :deep(button, .button, input) {
        min-width: 115px;
      }
    }
  }

</style>