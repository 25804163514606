<script setup lang="ts">

</script>

<template>
  <div class="page-container">

    <div class="left-menu-container">
      <slot name="left-menu"></slot>
    </div>
    <div class="page-content-container">
      <slot name="page-header"></slot>
      <slot name="page"></slot>
      <slot name="page-footer"></slot>
    </div>
    <slot name="user-manual"></slot>
  </div>
</template>

<style lang="scss" scoped>

.page-container {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  $menu_width: 280px;

  .left-menu-container {
    width: $menu_width;
    min-width: $menu_width;
    height: 100%;
    z-index: 1;
  }

  .page-content-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: calc(100% - #{$menu_width});
    height: 100%;
    background-color: var(--background-canvas);
  }
}

</style>


