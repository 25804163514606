import {AbstractValidatorCenter} from "@/lib/store/validator/center/abstract/AbstractValidatorCenter";
import {ON_INVALID_DO} from "@/lib/store/validator/center/abstract/enum/ON_INVALID_DO";
import EncapsulatedValidator from "@/lib/store/validator/center/abstract/type/EncapsulatedValidator";
import {IsNotNullLikeValidator} from "@/lib/store/validator/validator/common/IsNotNullLikeValidator";
import {SSegmentedTarget} from "@/store/Spatial2DAnnotation/model/SSegmentedTarget";


export class SegmentedTargetValidatorCenter<D extends SSegmentedTarget> extends AbstractValidatorCenter<D>
{
    protected encapsulatedValidators: EncapsulatedValidator[] = [
        {
            validator: new IsNotNullLikeValidator({onInvalidDo: ON_INVALID_DO.THROW}),
            getter: (entity: D) => entity.type
        },
    ];
}