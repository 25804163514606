
import {
    SegmentValidatorCenter
} from "@/store/Spatial2DAnnotation/validator/center/SegmentValidatorCenter";
import {AbstractSubStoreManager} from "@/lib/store/manager/abstract/AbstractSubStoreManager";
import {S2DSegment} from "@/store/Spatial2DAnnotation/model/S2DSegment";

export class SegmentManager extends AbstractSubStoreManager<S2DSegment>
{
    protected getValidatorCenterForEntity = (entity: S2DSegment) => {
        return new SegmentValidatorCenter(entity);
    };

    protected serve(entity: S2DSegment): S2DSegment {
        throw "On this project, annotations are inside bounding boxes and therefore, shouldn't be added this way, but only validated.";
    }
}