import {AbstractValidator} from "@/lib/store/validator/validator/abstract/AbstractValidator";
import {ON_INVALID_DO} from "@/lib/store/validator/center/abstract/enum/ON_INVALID_DO";

/**
 * Checks if a number is between two values
 */
export class IsNumberBetweenValidator extends AbstractValidator<number>
{
    protected min: number;
    protected max: number;

    protected errorMessage = "The given number %given_number% is not between %min% and %max%";

    constructor({onInvalidDo, min, max}: {onInvalidDo: ON_INVALID_DO, min: number, max: number})
    {
        super(onInvalidDo);

        this.min = min;
        this.max = max;
    }

    protected isValid(value: number): boolean
    {
        this.errorMessage = this.errorMessage
            .replace("%given_number%", "" + value)
            .replace("%min%", "" + this.min)
            .replace("%max%", "" + this.max)

        return value >= this.min && value <= this.max;
    }
}