import {SSegmentationTargetTypeParams} from "@/store/Spatial2DAnnotation/model/SSegmentationTargetTypeParams";

export const defaultSegmentationsParams: SSegmentationTargetTypeParams[] = [

];

export const getDisplayParamsFromType = (type: string) =>
{
    const displayParams = defaultSegmentationsParams.filter(params => params.value === type);

    if (displayParams.length === 0 || displayParams.length >= 2)
        throw "Can't get display params for type '" + type + "' : none or more than one exist with that name";

    return displayParams[0];
}