import AbstractCocoDatasetHelper, {
    CocoAnnotation,
    CocoCategory,
    CocoDataset,
    CocoImage
} from "@/lib/common/helpers/AbstractCocoDatasetHelper";
import {SSegmentedTarget} from "@/store/Spatial2DAnnotation/model/SSegmentedTarget";
import {SSegmentedImage} from "@/store/Spatial2DAnnotation/model/SSegmentedImage";

class CocoDatasetHelper extends AbstractCocoDatasetHelper
{
    public segmentedImageToCocoDataset = async (segmentedImage: SSegmentedImage) =>
    {
        const cocoDataset = this.getEmptyDataset()

        await this.addImage(cocoDataset, segmentedImage.image.file)

        segmentedImage.targets.map(target =>
        {
            const category = this.addCategoryIsNotExists(cocoDataset, target.type)

            this.addAnnotationFromTarget(cocoDataset, target, cocoDataset.images[0], category)
        })

        return cocoDataset;
    }

    public addAnnotationFromTarget = (dataset: CocoDataset, target: SSegmentedTarget, image: CocoImage, category: CocoCategory): CocoAnnotation =>
    {
        const id = dataset.annotations.length;

        const segmentation: number[] = [];

        let minLeft = image.width,
            minTop = image.height,
            maxLeft = 0,
            maxTop = 0;

        target.segments.map(segment =>
        {
            const leftPx = segment.left * image.width / 100,
                topPx = segment.top * image.height / 100;

            segmentation.push(leftPx, topPx);

            if (minLeft > leftPx)
                minLeft = leftPx;

            if (maxLeft < leftPx)
                maxLeft = leftPx;

            if (minTop > topPx)
                minTop = topPx;

            if (maxTop < topPx)
                maxTop = topPx;
        })

        const annotation: CocoAnnotation = {
            id: id,
            iscrowd: 0,
            image_id: image.id,
            category_id: category.id,
            caption: category.name,
            segmentation: [segmentation],
        }

        if (target.segments.length > 0)
            annotation.bbox = [minLeft, minTop, maxLeft - minLeft, maxTop - minTop];

        dataset.annotations.push(annotation)

        return annotation;
    }
}

export default new CocoDatasetHelper();