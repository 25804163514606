import {final} from "@/lib/common/decorators/final";
import {ON_INVALID_DO} from "@/lib/store/validator/center/abstract/enum/ON_INVALID_DO";
import SingleValidationResult from "@/lib/store/validator/center/abstract/type/SingleValidationResult";
import {ModelTransformer} from "@/lib/store/validator/transformer/ModelTransformer";

/**
 * This class represent a single validation pattern.
 * You can check IsNumberBetweenValidator for a practical application of it.
 *
 * <T> : the type of the given value
 */
export abstract class AbstractValidator<T>
{
    protected onInvalidDo: ON_INVALID_DO;

    protected errorMessage: string = "Validation Error";

    protected constructor(onInvalidDo: ON_INVALID_DO)
    {
        this.onInvalidDo = onInvalidDo;
    }

    @final
    public validate(value: T): SingleValidationResult
    {
        const isValid = this.isValid(value);

        return {
            isValid: isValid,
            onInvalidDo: this.onInvalidDo,
            errorMessage: isValid ? "" : this.errorMessage,
            modelTransformer: this.modelTransformer,
        }
    }

    protected abstract isValid(value: T): boolean;

    public modelTransformer: ModelTransformer<T> = (value: T): T => { return value; };
}