import {uuid} from "vue-uuid";

export const completeFilename = (filename: string, add: string) =>
{
  const splitName = filename.split(".");

  if (splitName.length - 2 >= 0)
    splitName[splitName.length - 2] += add;
  else
    splitName[0] += add;

  return splitName.join(".");
}

export const changeFilenameExtension = (filename: string, newExtension: string) =>
{
  const splitName = filename.split(".");

  splitName[splitName.length - 1] = newExtension;

  return splitName.join(".");
}

export const generateInstanceId = () => {
  return uuid.v4();
}

export const spaceCamelCase = (camelCaseWord: string) => {
  return camelCaseWord.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export const removeExtensionFromFilename = (filename: string|String) => {
  return filename.replace(/\.[^/.]+$/, "")
}

export const hexToRgb = (hex: string) =>
{
  const  result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export const rgbToHex = (r: number, g: number, b: number) => {
  function componentToHex(c: number) {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}