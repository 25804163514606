import {Mutation, VuexModule} from "vuex-module-decorators";
import {Module as Mod} from "vuex";
import {SStoreData} from "@/lib/store/model/abstract/SStoreData";

/**
 * Please make sure to make all your store classes extend this one or one of its children.
 *
 * This store module is an interface for general framework working purpose.
 * To make it work, you can do something similar to what's described here : https://github.com/championswimmer/vuex-module-decorators/issues/203#issuecomment-562924885
 */
export class AbstractStore<D extends SStoreData, S = ThisType<any>> extends VuexModule
{
    protected _entries: D[];

    constructor(module: Mod<S, any>) {
        super(module);
        this.populate();
        this._entries = [];
    }

    get entries()
    {
        return this._entries;
    }

    get byInstanceId()
    {
        const _this = this;

        return function (instanceId: string, strict?: boolean): D|undefined
        {
            const entry = _this._entries.filter(entry => entry.instanceId === instanceId)
            const result = entry.length ? entry[0] : undefined;

            if (strict && typeof result === "undefined")
                throw "Can't find entry of instanceId "+ instanceId +" on strict mode";

            return result;
        }
    }

    @Mutation
    add(entry: D)
    {
        this._entries.push(entry);
    }

    @Mutation
    setByInstanceId({entry, instanceId}: {entry: D, instanceId: string})
    {
        this._entries[Object.keys(this._entries).filter(key => this._entries[key as unknown as number].instanceId === instanceId)[0] as unknown as number] = entry;
    }

    @Mutation
    removeByInstanceId(instanceId: string)
    {
        this._entries = this._entries.filter(entry => entry.instanceId !== instanceId);
    }

    @Mutation
    reset()
    {
        this._entries = [];
    }

    /**
     * To benefit from extendable stores, you need to extend this method.
     * When doing so, copy its content and uncomment it in your child store.
     * Then, replace ParentStore by the parent class (example : AbstractStore),
     * and ThisStore by your store class (ex : FilesStore).
     */
    public populate() {
        // // Get wrapped parent module to access getters
        // const base: any = Module({})(ParentStore);
        // // Copy parent getters to this store
        // Object.assign(ThisStore.getters, base.getters);
        // Object.assign(ThisStore.mutations, base.mutations);
    }
}