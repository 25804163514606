import {generateInstanceId} from "@/lib/common/utilities/StringUtilities";
import _ from "lodash";

interface onKeyPressed { (): void }

type ShortcutConfig = {
    shortcutCode: string,
    callback: onKeyPressed
}

export enum SPECIAL_SHORTCUT_CODE
{
    LEFT_CLICK = "LEFT_CLICK",
    WHEEL_DOWN = "WHEEL_DOWN",
    WHEEL_UP = "WHEEL_UP",
}

/**
 * Use this class to register your shortcuts.
 * You can use this page to retrieve the key codes you need : https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
 */
class ShortcutsManager
{
    /**
     * The shortcutCode is equal to the keyCode and may be appended by "Ctrl+" if requires Ctrl pressed at the same time
     * @protected
     */
    protected shortcuts: {
        [instanceId: string]: ShortcutConfig
    } = {};

    protected ctrlPart = "Ctrl+";
    protected altPart = "Alt+";
    protected shiftPart = "Shift+";

    constructor()
    {
        window.addEventListener('keydown', (e: KeyboardEvent) =>
        {
            if (e.repeat) return;

            this.checkAndRunShortcuts(e, e.code);
        })

        window.addEventListener('click', (e: MouseEvent) =>
        {
            this.checkAndRunShortcuts(e, SPECIAL_SHORTCUT_CODE.LEFT_CLICK);
        })

        const throttledWheel = _.throttle((e: WheelEvent) =>
        {
            console.log("Wheel delta :" + e.deltaY);

            if (e.deltaY >= 10)
                this.checkAndRunShortcuts(e, SPECIAL_SHORTCUT_CODE.WHEEL_DOWN, false);

            if (e.deltaY <= -10)
                this.checkAndRunShortcuts(e, SPECIAL_SHORTCUT_CODE.WHEEL_UP, false);

        }, 100);

        window.addEventListener('wheel', throttledWheel)
    }

    protected checkAndRunShortcuts = (e: MouseEvent|KeyboardEvent, code: string, preventDefault: boolean = true) =>
    {
        Object.values(this.shortcuts).forEach(shortcut =>
        {
            const shortcutCode = (e.ctrlKey ? this.ctrlPart : "") + (e.altKey ? this.altPart : "") + (e.shiftKey ? this.shiftPart : "") +  code;

            if (shortcut.shortcutCode === shortcutCode)
            {
                if (preventDefault)
                    e.preventDefault();

                shortcut.callback();
            }
        })
    }

    public getShortcutCode(keyCode: string, requiresCtrl: boolean = false, requiresAlt: boolean = false, requiresShift: boolean = false)
    {
        return (requiresCtrl ? this.ctrlPart : "") + (requiresAlt ? this.altPart : "") + (requiresShift ? this.shiftPart : "") +  keyCode
    }

    public hasRegistered(instanceId: string)
    {
        return instanceId in this.shortcuts;
    }

    public register(keyCode: string, requiresCtrl: boolean, requiresAlt: boolean, requiresShift: boolean, callback: onKeyPressed)
    {
        const instanceId = generateInstanceId();

        this.shortcuts[instanceId] = {
            shortcutCode: this.getShortcutCode(keyCode, requiresCtrl, requiresAlt, requiresShift),
            callback: callback
        };

        return instanceId;
    }

    public unregister(instanceId: string)
    {
        if (this.hasRegistered(instanceId))
            delete this.shortcuts[instanceId];
    }

    public unregisterAll()
    {
        this.shortcuts = {};
    }
}

export default new ShortcutsManager();