export type DownloadableResource = {
    url: string,
    filename: string,
}

export const createDownloadableResource = (resource : Blob | MediaSource | HTMLCanvasElement, filename: string) =>
{
    return {
        url: resource instanceof HTMLCanvasElement ? resource.toDataURL() : URL.createObjectURL(resource),
        filename: filename
    }
}