import CsvUtilities from "@/lib/common/utilities/CsvUtilities";

export type BlobFile = {
    blob: Blob,
    filename: string,
}

export type BlobFileCreationArgumentsHolder = {
    data: string,
    mimeType: string,
    filename: string
}

export const createBlobFile = ({data, mimeType, filename}: BlobFileCreationArgumentsHolder) =>
{
    return {
        blob: new Blob([new Uint8Array([]), data], {type: mimeType}),
        filename: filename
    }
}

export const createTextBlobFile = (data: string, filename: string) =>
{
    return createBlobFile({
        data: data,
        mimeType: 'text/plain',
        filename: filename
    })
}

export const createCsvBlobFile = (data: any[][], filename: string) =>
{
    return createBlobFile({
        data: CsvUtilities.arrayToCsv(data),
        mimeType: 'text/csv',
        filename: filename
    })
}

export const createJsonBlobFile = (data: any, filename: string) =>
{
    return createBlobFile({
        data: JSON.stringify(data),
        mimeType: 'application/json',
        filename: filename
    })
}

export const createBlobFiles = (blobFileCreationArgumentsHolders: BlobFileCreationArgumentsHolder[]) =>
{
    return blobFileCreationArgumentsHolders.map(blobFileCreationArgumentsHolder => createBlobFile(blobFileCreationArgumentsHolder))
}