import {Ref} from "vue";
import html2canvas from "html2canvas";

interface onResizeCallback { (): void }

export const onElementResize = (ref: Ref|HTMLElement, callback: onResizeCallback) => {
    if (ref instanceof HTMLElement)
        new ResizeObserver(callback).observe(ref)
    else if (ref.value) // ref.value if a dom element. ref.value.$el if a component
        new ResizeObserver(callback).observe("$el" in ref.value ? ref.value.$el : ref.value)
}

export interface onElementClonedCallback { (clonedDoc: Document): void }

/**
 * Creates a canvas with the same view as what's in a given dom element
 * @param domElement
 * @param onElementCloned   Callback used to control the cloned DOM (useful if the element may be unrendered)
 * @return Promise with (canvas: HTMLCanvasElement) as its callback
 */
export const screenshotDomElementToCanvas = async (domElement: HTMLElement, onElementCloned: onElementClonedCallback|undefined = undefined) =>
{
    return await html2canvas(domElement, {
        backgroundColor:null,
        onclone: function (clonedDoc)
        {
            if (onElementCloned)
                onElementCloned(clonedDoc);
        }
    });
}

/**
 * Loads a resource of an image for further processing.
 * This is useful if you want to get an image's dimensions without worrying if it's rendered or not.
 * @param element
 */
export const loadElementAsResource = (element: HTMLElement) =>
{
    return element.cloneNode(true);
}

/**
 * Loads a resource of an image for further processing.
 * This is useful if you want to get an image's dimensions without worrying if it's rendered or not.
 * @param src
 */
export const loadImageAsResource = (src: string) =>
{
    return new Promise(resolve =>
    {
        const imageResource = new Image();

        imageResource.addEventListener('load', () => resolve(imageResource));

        /*
         * Setting the Image.src is what starts the networking process
         * to populate an image.  After you set it, the browser fires
         * a request to get the resource.  We attached a load listener
         * which will be called once the request finishes and we have
         * image data
         */
        imageResource.src = src;
    });
}

export const canvasToBlob = (canvas: HTMLCanvasElement): Promise<Blob> => {
    return new Promise((resolve) => {
        canvas.toBlob(blob => {
            if (blob) resolve(blob);
        });
    });
}