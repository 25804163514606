export const insertKey = (key: number, value: any, arr: Array<any>) =>
{
  const newArray: any[] = [];
  let passedTheThreshold = false;

  for (let index = 0; index <= arr.length; index++)
  {
    if (index === key)
    {
      newArray.push(value);
      passedTheThreshold = true;
    }
    else if (passedTheThreshold)
      newArray.push(arr[index - 1])
    else
      newArray.push(arr[index])
  }
  
  return newArray;
}
