import {generateInstanceId} from "@/lib/common/utilities/StringUtilities";
import {AbstractValidatorCenter} from "@/lib/store/validator/center/abstract/AbstractValidatorCenter";
import {BaseValidatorCenter} from "@/lib/store/validator/center/base/BaseValidatorCenter";
import {SStoreData} from "@/lib/store/model/abstract/SStoreData";
import ValidationResult from "@/lib/store/validator/center/abstract/type/ValidationResult";

export abstract class AbstractSubStoreManager<D extends SStoreData>
{
  public static createInstanceId(defaultInstanceId: string|void)
  {
    return defaultInstanceId ? defaultInstanceId : generateInstanceId();
  }

  private trySetInstanceId(entity: D)
  {
    if (!entity.instanceId)
      entity.instanceId = generateInstanceId();
  }

  public validate(entity: D): ValidationResult
  {
    const validationResult = this.getValidatorCenterForEntity(entity).validateEntity();

    if (!validationResult.isValid)
      this.onValidationError(entity, validationResult.errorMessages.join("; "));

    this.trySetInstanceId(entity);

    return validationResult;
  }

  protected getValidatorCenterForEntity(entity: D): AbstractValidatorCenter<D>
  {
    return new BaseValidatorCenter<D>(entity);
  }

  public onValidationError(entry: D, errors: string)
  {
    // TODO code a proper error handler
    console.error(entry);
    throw "Error validating the entry (logged just up there) : " + errors;
  }
}