export default class UserManualData
{
  public static messages = [
    {
      title: 'Concept (1)',
      content: 'First, load images of what you want to segment. Then, add a target type (cat, human, etc). Then, with your mouse, click to add a dot, then click again to connect it to another one. Continue until you have covered your target. You can complete a target\'s segmentation by clicking on its first dot. Finally, download your segmentations using the "Download JSON" button on the bottom of the screen. You can also load your segmentations on unannotated images.',
    },
    {
      title: 'Concept (2)',
      content: 'You can adjust your target\'s segmentation by removing and adding segment dots, and by selecting between which segment dots you want to spawn a new one. However, you cannot drag and move segment dots.',
    },
    {
      title: 'Export',
      content: 'Annotations are exported in the CocoDataset format along with the original image, the image with the annotations visible as well as a mask image. The mask image contains the segmentations drawn using the color of their target type with the difference that each subsequent segmentation target of a same type on the same image will have its mask drawn with a slightly different color to allow for differentiation programmatically : (previous target of its type\'s color\'s r+0, g+0 and b+1).',
    },
    {
      title: 'Adding a target type',
      content: 'Fill the New target name field and click on the「Add」button',
    },
    {
      title: 'Selecting a target type',
      content: 'Click on the target type on the left menu. Be careful, if no target type is selected, you won\'t be able to annotate.',
    },
    {
      title: 'Adding a segment dot',
      content: '<span>Left Click</span> inside the image to add a segment dot. If you click on the first segment dot of this target, you will complete it, unselecting it automatically.',
    },
    {
      title: 'Removing a segment dot',
      content: 'Press <span>Shift + Left Click</span> on the segment dot you wish to remove.',
    },
    {
      title: 'Switching to the previous possible connections for the next spawnable segment dot',
      content: '<span>Scroll Up</span>.',
    },
    {
      title: 'Switching to the previous possible connections for the next spawnable segment dot',
      content: '<span>Scroll Down</span>.',
    },
    {
      title: 'Switching to the previous target',
      content: '<span>Shift + Scroll Up</span>.',
    },
    {
      title: 'Switching to the next target',
      content: '<span>Shift + Scroll Down</span>.',
    },
    {
      title: 'Copy previous image\'s annotations',
      content: 'Press the <span>Copy previous image\'s annotations</span> button. This will erase all of the current image\'s annotations and will position the previous image\'s by respecting the same image\'s size ratio.',
    },
    {
      title: 'Remove all current target\'s segment dots',
      content: 'Press the <span>Escape Key</span>',
    },
  ]
}

