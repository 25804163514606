import {Module} from 'vuex-module-decorators'
import {SImageData} from "@/lib/store/model/media/SImageData";
import {MediasStore} from "@/lib/store/holder/media/MediasStore";

@Module({
  name: "ImagesStore",
  namespaced: true,
  stateFactory: true
})
export class ImagesStore<D extends SImageData> extends MediasStore<D>
{
  public populate()
  {
    // Get wrapped parent module to access getters
    const base: any = Module({})(MediasStore);
    // Copy parent getters to child
    Object.assign(ImagesStore.getters, base.getters);
    Object.assign(ImagesStore.mutations, base.mutations);
  }

}