import {ref, Ref} from "vue";
import {AbstractStoreManagerBuilder} from "@/lib/store/manager/abstract/AbstractStoreManager";
import {onElementResize} from "@/lib/common/utilities/DomUtilities";

interface CursorHelperEventCallback { (): void }

type CursorHelperEvent = {
    instanceId: string,
    element: HTMLElement,
    isHovering: boolean,
    enterCallback: CursorHelperEventCallback,
    leaveCallback: CursorHelperEventCallback
}

class CursorHelperBuilder
{
    protected _top: number = 0;
    protected _left: number = 0;

    protected cursorHelperEvents: CursorHelperEvent[] = [];

    protected _cursorFollower: HTMLElement;

    constructor()
    {
        this._cursorFollower = document.createElement("div");
        this._cursorFollower.id = "cursor-follower";
        this._cursorFollower.style.width = "1px";
        this._cursorFollower.style.height = "1px";
        this._cursorFollower.style.position = "absolute";
        this._cursorFollower.style.zIndex = "-9999999999";

        document.body.append(this._cursorFollower);

        window.addEventListener("mousemove", (e: MouseEvent) =>
        {
            this.updateCoordinates(e.pageY, e.pageX);
        })

        window.onresize = function() {
            window.dispatchEvent(new Event('mousemove'))
        };
    }

    protected updateCoordinates = (top: number, left: number) =>
    {
        this._top = top;
        this._left = left;

        this._cursorFollower.style.top = this._top + "px";
        this._cursorFollower.style.left = this._left + "px";

        this.checkForTrueMouseHover();
    }

    get top () {
        return this._top;
    }

    get left () {
        return this._left;
    }

    get cursorFollower () {
        return this._cursorFollower;
    }

    protected checkForTrueMouseHover ()
    {
        this.cursorHelperEvents.map(event =>
        {
            // ignores the invisible elements
            const style = getComputedStyle(event.element);
            if (style.display === 'none') return false;
            if (style.visibility !== 'visible') return false;

            // checks if hovering
            const elementCoordinates = event.element.getBoundingClientRect();

            if (
                this.left >= elementCoordinates.left
                && this.left <= elementCoordinates.right
                && this.top >= elementCoordinates.top
                && this.top <= elementCoordinates.bottom
            ) {
                if (!event.isHovering)
                {
                    event.isHovering = true;
                    event.enterCallback();
                }
            }
            else if (event.isHovering) {
                event.isHovering = false;
                event.leaveCallback();
            }
        })
    }

    public watchTrueMouseHover = (element: HTMLElement, enterCallback: CursorHelperEventCallback, leaveCallback: CursorHelperEventCallback) =>
    {
        const instanceId = AbstractStoreManagerBuilder.createInstanceId();

        this.cursorHelperEvents.push({
            instanceId: instanceId,
            element: element,
            isHovering: false,
            enterCallback: enterCallback,
            leaveCallback: leaveCallback,
        })

        this.checkForTrueMouseHover();

        return instanceId;
    }

    public unwatchTrueMouseHover = (instanceId: string) =>
    {
        this.cursorHelperEvents.splice(this.cursorHelperEvents.findIndex(event => event.instanceId === instanceId), 1)
    }
}

export const CursorHelper = new CursorHelperBuilder();