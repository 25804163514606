import {final} from "@/lib/common/decorators/final";
import EncapsulatedValidator from "./type/EncapsulatedValidator";
import ValidationResult from "@/lib/store/validator/center/abstract/type/ValidationResult";
import SingleValidationResult from "@/lib/store/validator/center/abstract/type/SingleValidationResult";
import {ON_INVALID_DO} from "@/lib/store/validator/center/abstract/enum/ON_INVALID_DO";
import {AssignedModelTransformer} from "@/lib/store/validator/transformer/ModelTransformer";
import {SStoreData} from "@/lib/store/model/abstract/SStoreData";
import {IsNotNullLikeValidator} from "@/lib/store/validator/validator/common/IsNotNullLikeValidator";

/**
 * This class handles an entity type's validation.
 * It comprises all its validators encapsulated with the getter to get the data to test.
 * Also, if requested by the validators and the entity validated, it will transform the entity. (see ON_INVALID_DO.TRANSFORM)
 *
 * By descending this class, you only should need to define the encapsulatedValidators and then reference it in
 * the entity manager.
 */
export abstract class AbstractValidatorCenter<D extends SStoreData>
{
    protected entity: D;

    protected abstract encapsulatedValidators: EncapsulatedValidator[];

    private baseValidators: EncapsulatedValidator[] = [
        {
            validator: new IsNotNullLikeValidator({onInvalidDo: ON_INVALID_DO.THROW}),
            getter: (entity: D) => entity.instanceId
        }
    ];

    constructor(entity: D)
    {
        this.entity = entity;

        this.fuseValidators();
    }

    private fuseValidators = () =>
    {
        if (!this.encapsulatedValidators)
            this.encapsulatedValidators = [];

        this.encapsulatedValidators = [
            ...this.baseValidators,
            ...this.encapsulatedValidators,
        ]
    }

    public setEntity(entity: D) {
        this.entity = entity;
    }

    @final
    public validateEntity(): ValidationResult
    {
        let isValid = true;

        const returnedErrors: string[] = [],
            transformersToApply: AssignedModelTransformer<any>[] = [];

        this.encapsulatedValidators.forEach(encapsulatedValidator =>
        {
            encapsulatedValidator.result = encapsulatedValidator.validator.validate(encapsulatedValidator.getter(this.entity));
        });

        this.encapsulatedValidators.forEach(encapsulatedValidator =>
        {
            const validationResult = encapsulatedValidator.result as SingleValidationResult;

            if (!validationResult.isValid)
            {
                // there seems to be an error
                if (validationResult.onInvalidDo !== ON_INVALID_DO.TRANSFORM)
                    isValid = false;

                switch (validationResult.onInvalidDo)
                {
                    case ON_INVALID_DO.SILENT:
                    case ON_INVALID_DO.TRANSFORM:
                        // meh do nothing it's fine
                        break;

                    case ON_INVALID_DO.RETURN_ERROR:
                        returnedErrors.push(validationResult.errorMessage);
                        break;

                    case ON_INVALID_DO.CONSOLE_LOG:
                        console.log(validationResult.errorMessage)
                        break;

                    case ON_INVALID_DO.CONSOLE_ERROR:
                        console.error(validationResult.errorMessage)
                        break;

                    case ON_INVALID_DO.THROW:
                        throw validationResult.errorMessage;
                }
            }
        })

        if (isValid)
        {
            this.transformEntity();
        }

        return {
            isValid: isValid,
            errorMessages: returnedErrors,
            transformedEntity: this.entity
        };
    }

    protected transformEntity()
    {
        this.encapsulatedValidators.forEach(encapsulatedValidator =>
        {
            if (
                encapsulatedValidator.result?.onInvalidDo === ON_INVALID_DO.TRANSFORM
                && !encapsulatedValidator.result.isValid
            )
            {
                if (!encapsulatedValidator.setter)
                    throw "No setter defined despite ON_INVALID_DO.TRANSFORM set";

                encapsulatedValidator.setter(this.entity, encapsulatedValidator.validator.modelTransformer(encapsulatedValidator.getter(this.entity)));
            }
        });
    }
}