import {Module, Mutation} from 'vuex-module-decorators'
import {AbstractStore} from "@/lib/store/holder/abstract/AbstractStore";
import {
  SSegmentationTargetTypeParams
} from "@/store/Spatial2DAnnotation/model/SSegmentationTargetTypeParams";

@Module({
  name: "SegmentationTargetTypeParamsStore",
  namespaced: true,
  stateFactory: true
})

export class SegmentationTargetTypeParamsStore<D extends SSegmentationTargetTypeParams> extends AbstractStore<D>
{
  get selected()
  {
    return this._entries.filter(entry => entry.isSelected === true);
  }

  get byBodyPart()
  {
    const _this = this;

    return function (bodyPart: string, strict?: boolean): D|undefined
    {
      const entry = _this._entries.filter(entry => entry.value === bodyPart)
      const result = entry.length ? entry[0] : undefined;

      if (strict && typeof result === "undefined")
        throw "Can't find entry of bodyPart "+ bodyPart +" on strict mode";

      return result;
    }
  }

  @Mutation
  setIsSelected({instanceId, isSelected}: {instanceId: string, isSelected: boolean})
  {
    this._entries.forEach(entry => {
      entry.isSelected = false;
      if (entry.instanceId === instanceId)
        entry.isSelected = isSelected;
    });
  }

  @Mutation
  setIsSelectedBasedOnValue({value, isSelected}: {value: string, isSelected: boolean})
  {
    this._entries.forEach(entry => {
      entry.isSelected = false;
      if (entry.value === value)
        entry.isSelected = isSelected;
    });
  }

  @Mutation
  resetSelected()
  {
    this._entries.forEach(entry => {
      entry.isSelected = false;
    });
  }

  @Mutation
  switchToNextSelected()
  {
    const selectedIndex = this._entries.findIndex(entry => entry.isSelected === true);

    let nextOneIndex = selectedIndex + 1;

    if (nextOneIndex >= this._entries.length)
      nextOneIndex = 0;

    this._entries.forEach(entry => {
      entry.isSelected = false;
    });

    this._entries[nextOneIndex].isSelected = true;
  }

  @Mutation
  switchToPreviousSelected()
  {
    const selectedIndex = this._entries.findIndex(entry => entry.isSelected === true);

    let nextOneIndex = selectedIndex - 1;

    if (nextOneIndex < 0)
      nextOneIndex = this._entries.length - 1;

    this._entries.forEach(entry => {
      entry.isSelected = false;
    });

    this._entries[nextOneIndex].isSelected = true;
  }

  public populate()
  {
    // Get wrapped parent module to access getters
    const base: any = Module({})(AbstractStore);
    // Copy parent getters to child
    Object.assign(SegmentationTargetTypeParamsStore.getters, base.getters);
    Object.assign(SegmentationTargetTypeParamsStore.mutations, base.mutations);
  }
}