import {createStore, ModuleTree} from 'vuex'

const context_lib = require.context('./holder', true, /^((?!Abstract).)*$/);
const context_app = require.context('./../../store', true, /^((?!Abstract).)*(Store)$/);
const modules: ModuleTree<any> = {};

const registerContextToStore = (context: __WebpackModuleApi.RequireContext) =>
{
    context.keys().forEach((file) => {

        const splitPath = file.split("/");

        // create the module name from file
        const moduleName = splitPath[splitPath.length - 1];

        if (!moduleName.includes(".ts"))
        {
            // register file context under module name
            modules[moduleName] = (context(file).default || context(file))[moduleName];

            // override namespaced option
            modules[moduleName].namespaced = true;
        }
    });
}

registerContextToStore(context_lib);
registerContextToStore(context_app);


export default createStore({
    strict: process.env.NODE_ENV !== 'production',
    modules: modules
});