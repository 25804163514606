import {ON_INVALID_DO} from "@/lib/store/validator/center/abstract/enum/ON_INVALID_DO";
import {AbstractValidatorCenter} from "@/lib/store/validator/center/abstract/AbstractValidatorCenter";
import EncapsulatedValidator from "@/lib/store/validator/center/abstract/type/EncapsulatedValidator";
import {SStoreData} from "@/lib/store/model/abstract/SStoreData";
import {IsNotNullLikeValidator} from "@/lib/store/validator/validator/common/IsNotNullLikeValidator";

export class BaseValidatorCenter<D extends SStoreData> extends AbstractValidatorCenter<D>
{
    protected encapsulatedValidators: EncapsulatedValidator[] =
        [
        {
            validator: new IsNotNullLikeValidator({onInvalidDo: ON_INVALID_DO.THROW}),
            getter: (entity: SStoreData) => entity.instanceId
        },
    ];
}