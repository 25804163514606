import {getModule} from "vuex-module-decorators";
import store from "../..";
import {MediasStoreManagerBuilder} from "@/lib/store/manager/media/MediasStoreManager";
import {SImageData} from "@/lib/store/model/media/SImageData";
import {ImagesStore} from "@/lib/store/holder/media/ImagesStore";

export class ImagesStoreManagerBuilder<S extends ImagesStore<D>, D extends SImageData> extends MediasStoreManagerBuilder<S, D>
{
  protected _store: ImagesStore<SImageData> = getModule(ImagesStore, store);
}

export const ImagesStoreManager = new ImagesStoreManagerBuilder();