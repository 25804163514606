import {getModule} from "vuex-module-decorators";
import store from "../..";
import {PopUpsStore} from "@/lib/store/holder/popup/PopUpsStore";
import {SPopUpState} from "@/lib/store/model/popup/SPopupState";
import {AbstractStoreManagerBuilder} from "@/lib/store/manager/abstract/AbstractStoreManager";

export const defaultPopUpsInstanceIds = {
  userManual: "userManual"
}

export class PopUpsStoreManagerBuilder<S extends PopUpsStore<D>, D extends SPopUpState> extends AbstractStoreManagerBuilder<S, D>
{
  protected _store: PopUpsStore<SPopUpState> = getModule(PopUpsStore, store);

  protected createPopupState(defaultVisibility: boolean = false, defaultInstanceId: string|void): SPopUpState
  {
    return {
      instanceId: AbstractStoreManagerBuilder.createInstanceId(defaultInstanceId),
      isVisible: defaultVisibility
    }
  }

  public registerPopUp(defaultVisibility: boolean = false, defaultInstanceId: string|undefined = undefined)
  {
    return this.add(
        this.createPopupState(defaultVisibility, defaultInstanceId) as D,
    );
  }
}

export const PopUpsStoreManager = new PopUpsStoreManagerBuilder();