import {getModule} from "vuex-module-decorators";
import store from "../..";
import {MediasStore} from "@/lib/store/holder/media/MediasStore";
import {SMediaData} from "@/lib/store/model/media/SMediaData";
import {AbstractStoreManagerBuilder} from "@/lib/store/manager/abstract/AbstractStoreManager";

export class MediasStoreManagerBuilder<S extends MediasStore<D>, D extends SMediaData> extends AbstractStoreManagerBuilder<S, D>
{
  protected _store: MediasStore<SMediaData> = getModule(MediasStore, store);

  public static createMediaData(mediaFile: File, defaultInstanceId: string|undefined = undefined)
  {
    return {
      instanceId: defaultInstanceId ? defaultInstanceId.toString() : AbstractStoreManagerBuilder.createInstanceId(defaultInstanceId),
      file: mediaFile,
      url: URL.createObjectURL(mediaFile),
      name: mediaFile.name,
      type: mediaFile.type,
    }
  }

  public addMedia(mediaFile: File, defaultInstanceId: string|undefined = undefined)
  {
    return this.add(MediasStoreManagerBuilder.createMediaData(mediaFile, defaultInstanceId) as unknown as D);
  }
}

export const MediasStoreManager = new MediasStoreManagerBuilder();