import DownloadHelper from "@/lib/common/helpers/DownloadHelper";
import {canvasToBlob, screenshotDomElementToCanvas} from "@/lib/common/utilities/DomUtilities";
import {BlobFile, createJsonBlobFile} from "@/lib/common/helpers/type/BlobFile";
import {
    changeFilenameExtension,
    completeFilename, hexToRgb,
    removeExtensionFromFilename, rgbToHex
} from "@/lib/common/utilities/StringUtilities";
import {getHeightAndWidthFromImageFile, loadImage, overlayCanvasOntoImage} from "@/lib/common/utilities/ImageUtilities";
import {createDownloadableResource, DownloadableResource} from "@/lib/common/helpers/type/DownloadableResource";
import OverlayableImage from "@/lib/components/frame/image/OverlayableImage.vue";
import {
    SegmentedImagesStoreManager
} from "@/store/Spatial2DAnnotation/manager/SegmentedImagesStoreManager";
import {SSegmentedImage} from "@/store/Spatial2DAnnotation/model/SSegmentedImage";
import CocoDatasetHelper from "@/helpers/CocoDatasetHelper";

type CarouselImageType = {
    HTMLImage: HTMLImageElement,
}

class AnnotatableImagesDownloadHelper
{
    public async downloadAllAnnotatedImagesData(carouselImages: typeof OverlayableImage[])
    {
        const folderName = "segmented annotations";
        const zipName = new Date().toISOString() + " segmented annotations";

        const annotatedCowImagesData = SegmentedImagesStoreManager.store.annotated;
        const annotatedImages = await this.prepareAnnotatedImages(annotatedCowImagesData, carouselImages);

        const downloadResources: DownloadableResource[] = [];

        for (const key in annotatedCowImagesData)
        {
            const mainDownloadableResources = await this.getAnnotatableImageDataBlobs(annotatedCowImagesData[key]);
            const annotatedImage = annotatedImages.filter(annotatedImage => annotatedImage.instanceId === annotatedCowImagesData[key].image.instanceId);

            mainDownloadableResources.forEach(mainDownloadableResource => {
                downloadResources.push(mainDownloadableResource);
            })

            annotatedImage.forEach(annotatedImage => {
                downloadResources.push(createDownloadableResource(annotatedImage.blobFile.blob, annotatedImage.blobFile.filename));
            })
        }

        return await DownloadHelper.zipAndDownloadResources(downloadResources, folderName, zipName);
    }

    protected async getAnnotatableImageDataBlobs(annotatableImageData: SSegmentedImage)
    {
        const resource: DownloadableResource[] = [];

        const jsonFilename = removeExtensionFromFilename(annotatableImageData.image.name) + ".json";

        const cocoDataset = await CocoDatasetHelper.segmentedImageToCocoDataset(annotatableImageData)

        resource.push(createDownloadableResource(annotatableImageData.image.file, annotatableImageData.image.file.name));
        resource.push(createDownloadableResource(createJsonBlobFile(cocoDataset, jsonFilename).blob, jsonFilename));

        return resource;
    }

    protected async prepareAnnotatedImages(annotatableImagesData: SSegmentedImage[], carouselImages: typeof OverlayableImage[])
    {
        const imagesInstanceIds = annotatableImagesData.map(annotatableImageData => annotatableImageData.image.instanceId);

        const instanceIdedBlobFiles: {
            instanceId: string,
            blobFile: BlobFile
        }[] = [];

        // @ts-ignore don't trust webstorm on this one
        await carouselImages.reduce( async (previousPromise: typeof Promise, carouselImage: CarouselImageType) =>
        {
            // for each of them
            await previousPromise;

            const instanceId = carouselImage.HTMLImage.dataset.instanceId,
                name = carouselImage.HTMLImage.dataset.name,
                container = document.getElementById("overlayable-image-container-" + instanceId), // the container is the one being possibily unrendered, so we need to get it
                overlay = document.getElementById("image-overlay-" + instanceId); // refs don't seem to work properly, so using the good old method

            if (
                instanceId && name && overlay && container
                && imagesInstanceIds.includes(instanceId)
            )
            {
                // download a copy of their view when displayed
                const filename = completeFilename(name, "-annotated");

                const canvas: HTMLCanvasElement = await screenshotDomElementToCanvas(overlay, (clonedDoc: Document) =>
                {
                    // but before the download, assures this one is displayed in the cloned document (as they can be unrendered when clicking the download button)
                    Array.prototype.map.call(clonedDoc.getElementsByClassName(container.className), (element) => element.style.display = "none");
                    // @ts-ignore
                    clonedDoc.getElementById(container.id).style.display = 'block';
                    [...clonedDoc.getElementsByClassName("dot-annotation-container")].map((element: HTMLElement) => element.classList.remove("current"));
                    [...clonedDoc.getElementsByClassName("annotations")].map((element: HTMLElement) => element.style.display = "block");
                });

                instanceIdedBlobFiles.push({
                    instanceId: instanceId,
                    blobFile: {
                        blob: await canvasToBlob(await overlayCanvasOntoImage(canvas, carouselImage.HTMLImage)),
                        filename: filename
                    }
                });

                const annotatableImageData = annotatableImagesData.filter(annotatableImageData => annotatableImageData.image.instanceId === instanceId)[0];

                instanceIdedBlobFiles.push({
                    instanceId: instanceId,
                    blobFile: {
                        blob: await this.createMaskBlob(annotatableImageData),
                        filename: "mask." + changeFilenameExtension(filename, "png")
                    }
                })
            }
        }, Promise.resolve())

        return instanceIdedBlobFiles;
    }

    protected createMaskBlob = async (annotatableImageData: SSegmentedImage) =>
    {
        type UsedColor = {
            hex: string,
            rgb: {r: number, g: number, b: number},
            timesUsed: number
        }

        const canvas = document.createElement('canvas'),
            imageDimensions = await getHeightAndWidthFromImageFile(annotatableImageData.image.file),
            usedColors: UsedColor[] = []

        canvas.width = imageDimensions.width;
        canvas.height = imageDimensions.height;

        const ctx= canvas.getContext("2d");

        if (!ctx)
            throw "Unsupported canvas context";

        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        annotatableImageData.targets.map(target =>
        {
            let usedColorIndex = usedColors.findIndex(usedColor => usedColor.hex === target.colorCode)

            if (usedColorIndex === -1)
            {
                usedColors.push({
                    hex: target.colorCode,
                    rgb: hexToRgb(target.colorCode) as {r: number, g: number, b: number},
                    timesUsed: 1
                })

                usedColorIndex = usedColors.length - 1;
            }
            else {
                usedColors[usedColorIndex].timesUsed++
            }

            /// use save when using clip Path
            ctx.save();

            ctx.beginPath();

            let i = 0;

            target.segments.map(segment =>
            {
                const segmentX = segment.left * imageDimensions.width / 100;
                const segmentY = segment.top * imageDimensions.height / 100;

                if (0 === i++)
                    ctx.moveTo(segmentX, segmentY);
                else
                    ctx.lineTo(segmentX, segmentY);
            })

            ctx.closePath();

            /// define this Path as clipping mask
            ctx.clip();

            const nextRgbNumber = (current: number, timesCalled: number) => {
                return (current + timesCalled) > 255 ? 0 : (current + timesCalled - 1);
            }

            /// draw the image
            const hex = rgbToHex(
                nextRgbNumber(usedColors[usedColorIndex].rgb.r, 1),
                nextRgbNumber(usedColors[usedColorIndex].rgb.g, 1),
                nextRgbNumber(usedColors[usedColorIndex].rgb.b, usedColors[usedColorIndex].timesUsed)
            );

            ctx.fillStyle = hex;

            ctx.fillRect(0, 0, canvas.width, canvas.height);

            /// reset clip to default
            ctx.restore();
        })

        const image = await loadImage("" + annotatableImageData.image.url)

        return await canvasToBlob(await overlayCanvasOntoImage(canvas, image))
    }
}

export default new AnnotatableImagesDownloadHelper();