export const defaultSuggestedColors = [
    "#aa47bc",
    "#fe5722",
    "#007e7a",
    "#fcc02e",
    "#5eab9d",
    "#ff0b70",
    "#25cc0e",
    "#4362ce",
    "#ec2626",
    "#aae000",
    "#fa6ccf",
];