import {AbstractValidator} from "@/lib/store/validator/validator/abstract/AbstractValidator";
import {ON_INVALID_DO} from "@/lib/store/validator/center/abstract/enum/ON_INVALID_DO";

/**
 * Checks if null or undefined
 */
export class IsNotNullLikeValidator extends AbstractValidator<number>
{
    protected errorMessage = "The given data %data% cannot be null or undefined";

    constructor({onInvalidDo}: {onInvalidDo: ON_INVALID_DO})
    {
        super(onInvalidDo);
    }

    protected isValid(value: any): boolean
    {
        this.errorMessage = this.errorMessage
            .replace("%data%", "" + value)

        return !( value === null || typeof value === "undefined" );
    }
}