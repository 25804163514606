import {toRaw} from "vue";
import {generateInstanceId} from "@/lib/common/utilities/StringUtilities";
import config from "@/config";
import {
    SegmentedTargetValidatorCenter
} from "@/store/Spatial2DAnnotation/validator/center/SegmentedTargetValidatorCenter";
import {
    SegmentedImagesStoreManager
} from "@/store/Spatial2DAnnotation/manager/SegmentedImagesStoreManager";
import {
    SegmentManager
} from "@/store/Spatial2DAnnotation/manager/subManager/SegmentManager";
import {AbstractValidatorCenter} from "@/lib/store/validator/center/abstract/AbstractValidatorCenter";
import {AbstractSubStoreManager} from "@/lib/store/manager/abstract/AbstractSubStoreManager";
import ValidationResult from "@/lib/store/validator/center/abstract/type/ValidationResult";
import {SSegmentedTarget} from "@/store/Spatial2DAnnotation/model/SSegmentedTarget";
import {S2DSegment} from "@/store/Spatial2DAnnotation/model/S2DSegment";
import {insertKey} from "@/lib/common/utilities/ArrayUtilities";

export class SegmentedTargetManager extends AbstractSubStoreManager<SSegmentedTarget>
{
    protected annotationTypeToValidate: string|undefined;
    protected maxInstanceForType: number|undefined;

    constructor(annotationTypeToValidate: string|void)
    {
        super();

        if (annotationTypeToValidate)
        {
            this.annotationTypeToValidate = annotationTypeToValidate;
            this.maxInstanceForType = 99999999999999;
        }
    }

    public createNew(type: string, colorCode: string): SSegmentedTarget
    {
        return {
            type: type,
            colorCode: colorCode,
            instanceId: generateInstanceId(),
            segments: [],
        }
    }

    protected getValidatorCenterForEntity = (entity: SSegmentedTarget): AbstractValidatorCenter<SSegmentedTarget> => {
        return new SegmentedTargetValidatorCenter<SSegmentedTarget>(entity);
    };

    public tryAddNew(type: string, colorCode: string): SSegmentedTarget
    {
        const result = this.validate(this.createNew(type, colorCode));

        if (!result.isValid)
            throw result.errorMessages;

        const segmentedTarget = result.transformedEntity as SSegmentedTarget;

        SegmentedImagesStoreManager.addTargetForCurrent(segmentedTarget)

        return segmentedTarget;
    }

    public addSegment = (target: SSegmentedTarget, segment: S2DSegment, segmentKey: number|undefined) => {
        const rawTarget = toRaw(target);

        if (typeof segmentKey === "undefined")
            rawTarget.segments.push(segment);
        else {
            rawTarget.segments = insertKey(segmentKey + 1, segment, rawTarget.segments)
        }

        const result = this.validate(rawTarget);

        if (!result.isValid)
            return result.errorMessages;

        const ValidatedBoundingBox = result.transformedEntity as SSegmentedTarget;

        SegmentedImagesStoreManager.setSegmentsForCurrentTarget(ValidatedBoundingBox.segments);

        return ValidatedBoundingBox.segments[ValidatedBoundingBox.segments.length - 1];
    }

    public validate(entity: SSegmentedTarget): ValidationResult
    {
        const errors: string[] = [],
            segments: S2DSegment[] = [];

        for ( const key in entity.segments )
        {
            const annotation = entity.segments[key],
                manager = new SegmentManager(),
                validationResult = manager.validate(annotation);

            if (!validationResult.isValid)
                errors.concat(...validationResult.errorMessages);
            else
                segments.push(validationResult.transformedEntity as S2DSegment);
        }

        entity.segments = segments;

        return super.validate(entity);
    }
}