import {getHeightAndWidthFromImageFile} from "@/lib/common/utilities/ImageUtilities";

export type CocoDataset = {
    info: CocoInfo,
    images: CocoImage[],
    annotations: CocoAnnotation[],
    licenses: CocoLicense[],
    categories: CocoCategory[]
}

// General info about the dataset
export type CocoInfo = {
    year: number, // year of the dataset's release
    version: string, // dataset's version
    description: string, // description of the dataset
    contributor?: string, // creator of the dataset
    url: string, // website that allowed the creation of the dataset
    data_created: string, // date of the creation of the dataset
}

// List of all images in the dataset
export type CocoImage = {
    id: number, // unique identifier
    width: number, // the image width
    height: number, // the image height
    file_name: string, // the file name of the image
    license?: number, // its license id
    flickr_url?: string, // (optional) the url to the image on flickr
    coco_url?: string, // (optional) the url to the image on the Coco website
    date_capture: string, // the date of the capture
}

// List of all object annotations for each image
export type CocoAnnotation = {
    id: number, // unique identifier
    image_id: number, // the image's id from which the annotation is from
    category_id: number, //  the annotation's category id (ex : the id of "person")
    segmentation?: number[][], // the coordinates of each point making the segmentation, per section (one block = 1 section) in the shape of (dot1x, dot1y, dot2x, dot2y, ...)
    num_keypoints?: number, // the number of keypoints
    keypoints?: number[], // the skeleton's keypoints inside the segmentated item
    area?: number, // the number of pixels defining the area inside the segmentation
    bbox?: [number, number, number, number], // the bounding box that fills the target annotation (x, y, width, height)
    iscrowd: number, // if the annotation actually includes multiple targets
    caption: string, // a description of the annotation (ex : "a person sitting")
}

// Information about the licenses for the images in the dataset
/* The License may be among these 8 :
- id : 1. Name : Attribution-NonCommercial-ShareAlike License. Url: http://creativecommons.org/licenses/by-nc-sa/2.0/
- id : 2. Name : Attribution-NonCommercial License. Url: http://creativecommons.org/licenses/by-nc/2.0/
- id : 3. Name : Attribution-NonCommercial-NoDerivs License. Url : http://creativecommons.org/licenses/by-nc-nd/2.0/
- id : 4. Name : Attribution License. Url : http://creativecommons.org/licenses/by/2.0/
- id : 5. Name : Attribution-ShareAlike License. Url : http://creativecommons.org/licenses/by-sa/2.0/
- id : 6. Name : Attribution-NoDerivs License. Url : http://creativecommons.org/licenses/by-nd/2.0/
- id : 7. Name : No known copyright restrictions. Url : http://flickr.com/commons/usage/
- id : 8. Name : United States Government Work. Url : http://www.usa.gov/copyright.shtml
 */
export type CocoLicense = {
    id: number, // unique identifier
    name: string, // name of the license (ex : Attribution-NonCommercial-ShareAlike 4.0 International)
    url: string // url to the license
}

// List of all dataset object categories
export type CocoCategory = {
    supercategory?: string, // (optional) the parent category
    id: number, // unique identifier
    name: string, // name of the category
    keypoints?: string[], // キーポイントの順番（=ID）と名称
    skeleton?: [number, number][] // 描画のためのキーポイントの連結関係. "keypoints"での記述順番がIDと対応するため、例えば[16,14]は"left_ankle"と"left_knee"を繋ぐことを意味する。
}

/**
 * Handles CocoDataset format
 * More info on : https://qiita.com/kHz/items/8c06d0cb620f268f4b3e
 */
export default abstract class AbstractCocoDatasetHelper
{
    public getEmptyDataset = (): CocoDataset =>
    {
        return {
            info: {
                year: new Date().getFullYear(),
                version: "1.0.0",
                contributor: "",
                description: "This dataset was created with webfarmer's annotation tool",
                url: window.location.origin,
                data_created: new Date().toISOString().split('T')[0]
            },
            annotations: [],
            images: [],
            licenses: [],
            categories: []
        }
    }

    public addImage = async (dataset: CocoDataset, image: File): Promise<CocoImage> =>
    {
        const id = dataset.images.length;

        const dimensions = await getHeightAndWidthFromImageFile(image)

        const cocoImage = {
            id: id,
            file_name: image.name,
            width: dimensions.width,
            height: dimensions.height,
            date_capture: new Date(image.lastModified).toISOString().split('T')[0]
        };

        dataset.images.push(cocoImage)

        return cocoImage;
    }

    public addCategoryIsNotExists = (dataset: CocoDataset, name: string, parentCategory?: string): CocoCategory =>
    {
        const existingCategory = dataset.categories.filter(category => category.name === name);

        if (existingCategory.length > 0)
            return existingCategory[0];
        else
        {
            const id = dataset.categories.length;

            const category: CocoCategory = {
                id: id,
                name: name,
            }

            if (parentCategory)
                category.supercategory = parentCategory;

            dataset.categories.push(category)

            return category;
        }
    }
}