import {Module} from 'vuex-module-decorators'
import store from "@/lib/store";
import {SVideoData} from "@/lib/store/model/media/SVideoData";
import {MediasStore} from "@/lib/store/holder/media/MediasStore";

@Module({
    name: "VideosStore",
    store: store,
    namespaced: true,
    stateFactory: true
})
export class VideosStore<D extends SVideoData> extends MediasStore<D>
{
    public populate()
    {
        // Get wrapped parent module to access getters
        const base: any = Module({})(MediasStore);
        // Copy parent getters to child
        Object.assign(VideosStore.getters, base.getters);
        Object.assign(VideosStore.mutations, base.mutations);
    }
}
