import {Module} from "vuex-module-decorators";
import {SMediaData} from "@/lib/store/model/media/SMediaData";
import {AbstractStore} from "@/lib/store/holder/abstract/AbstractStore";

@Module({
  name: "MediasStore",
  namespaced: true,
  stateFactory: true
})
export class MediasStore<D extends SMediaData> extends AbstractStore<D>
{
  get medias(): D[]
  {
    return this.entries;
  }

  get mediaOfInstanceId()
  {
    return this.byInstanceId;
  }

  public populate()
  {
    // Get wrapped parent module to access getters
    const base: any = Module({})(AbstractStore);
    // Copy parent getters to child
    Object.assign(MediasStore.getters, base.getters);
    Object.assign(MediasStore.mutations, base.mutations);
  }
}