import {loadImageAsResource} from "@/lib/common/utilities/DomUtilities";

export const overlayCanvasOntoImage = async (canvas: HTMLCanvasElement, image: HTMLImageElement): Promise<HTMLCanvasElement> => {
  return loadImageAsResource(image.src).then((imageResource: HTMLImageElement) => {
    const fusingCanvas: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;

    fusingCanvas.width = image.naturalWidth;
    fusingCanvas.height = image.naturalHeight;

    const context = fusingCanvas.getContext("2d");

    if (context) {
      context.drawImage(imageResource, 0, 0, imageResource.naturalWidth, imageResource.naturalHeight);
      context.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, fusingCanvas.width, fusingCanvas.height);
    }

    return fusingCanvas;
  })
}

/**
 * Load an image from a given URL
 */
export const loadImage = (url: string): Promise<HTMLImageElement> =>
{
  return new Promise(resolve =>
  {
    const image = new Image();

    image.addEventListener('load', () => {
      resolve(image);
    });

    image.src = url;
  });
}

export const getHeightAndWidthFromImageFile = (file: File): Promise<{width: number, height: number}> => new Promise(resolve => {
  const img = new Image()
  img.onload = () => {
    resolve({
      height: img.height,
      width: img.width
    })
  }
  img.src = URL.createObjectURL(file)
})