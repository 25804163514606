import {getModule} from "vuex-module-decorators";
import store from "@/lib/store";
import FileHelper from "@/lib/common/helpers/FileHelper";
import {removeExtensionFromFilename} from "@/lib/common/utilities/StringUtilities";
import {AbstractStoreManagerBuilder} from "@/lib/store/manager/abstract/AbstractStoreManager";
import {MediasStoreManagerBuilder} from "@/lib/store/manager/media/MediasStoreManager";
import {SImageData} from "@/lib/store/model/media/SImageData";
import {SegmentedImagesStore} from "@/store/Spatial2DAnnotation/holder/SegmentedImagesStore";
import {SSegmentedImage} from "@/store/Spatial2DAnnotation/model/SSegmentedImage";
import {S2DSegment} from "@/store/Spatial2DAnnotation/model/S2DSegment";
import {
  SegmentedImageValidatorCenter
} from "@/store/Spatial2DAnnotation/validator/center/SegmentedImageValidatorCenter";
import {AbstractValidatorCenter} from "@/lib/store/validator/center/abstract/AbstractValidatorCenter";
import {
  SegmentedTargetManager
} from "@/store/Spatial2DAnnotation/manager/subManager/SegmentedTargetManager";
import {SSegmentedTarget} from "@/store/Spatial2DAnnotation/model/SSegmentedTarget";
import {
  SegmentationTargetTypeParamsStoreManager
} from "@/store/Spatial2DAnnotation/manager/SegmentationTargetTypeParamsStoreManager";
import {CocoDataset} from "@/lib/common/helpers/AbstractCocoDatasetHelper";

export class SegmentedImagesStoreManagerBuilder extends AbstractStoreManagerBuilder<SegmentedImagesStore<SSegmentedImage>, SSegmentedImage>
{
  protected _store: SegmentedImagesStore<SSegmentedImage> = getModule(SegmentedImagesStore, store);

  protected getValidatorCenterForEntity = (entity: SSegmentedImage): AbstractValidatorCenter<SSegmentedImage> => {
    return new SegmentedImageValidatorCenter(entity);
  };

  // ------------------------------
  //  Current cat image and its bounding box
  // ------------------------------

  public setCurrentSegmentedImage = (currentSegmentedImage: SSegmentedImage) => {
    this._store.setCurrentSegmentedImage(currentSegmentedImage.instanceId);
  }
  public setCurrentSegmentedTarget = (currentSegmentedTarget: SSegmentedTarget) =>
  {
    this._store.setCurrentSegmentedImage(currentSegmentedTarget.instanceId);
    // SegmentedBodyPartDisplayParamsStoreManager.resetBaseOnBoundingBox(currentSegmentedTarget);
  }

  public setCurrentSegmentedTargetBasedOnType = (type: string) =>
  {
    this._store.resetIsCurrentTargetForCurrentSegmentedImage();
    this._store.setFirstUncompletedTargetOfTypeIsCurrent(type);
    // SegmentedBodyPartDisplayParamsStoreManager.resetBaseOnBoundingBox(currentSegmentedTarget);
  }

  protected getCheckedCurrentSegmentedImage = () => {
    if (typeof this.store.currentSegmentedImage === "undefined")
      throw "this._currentSegmentedImage shouldn't be undefined"

    return this.store.currentSegmentedImage;
  }
  protected getCheckedCurrentSegmentedTarget = () => {
    if (typeof this.store.currentSegmentedTarget === "undefined")
      throw "this._currentSegmentedTarget shouldn't be undefined"

    return this.store.currentSegmentedTarget;
  }

  // ------------------------------
  // Add
  // ------------------------------

  public addEmpty(file: File, defaultInstanceId: string|undefined = undefined)
  {
    return this.add({
      instanceId: AbstractStoreManagerBuilder.createInstanceId(defaultInstanceId),
      image: MediasStoreManagerBuilder.createMediaData(file),
      targets: [],
    });
  }

  public async addFromFile(file: File)
  {
    const json = await FileHelper.readAsText(file);
    const data = JSON.parse(json) as CocoDataset;
    const imageName = removeExtensionFromFilename(file.name);

    const targetEntries = this._store.entries.filter(entry => removeExtensionFromFilename(entry.image.name) === imageName);

    if (targetEntries.length === 0)
    {
      alert("No image of name '" + imageName + "' is loaded. Provided data ignored.")
      return;
    }

    const targetEntry = targetEntries[0];

    const result = this.validate(targetEntry);

    const finalEntry: SSegmentedImage = result.transformedEntity as SSegmentedImage;

    const targets: SSegmentedTarget[] = [];

    data.annotations.map(annotation =>
    {
      if (annotation.segmentation && annotation.segmentation[0])
      {
        const target: SSegmentedTarget = {
          instanceId: AbstractStoreManagerBuilder.createInstanceId(),
          type: annotation.caption,
          colorCode: '#'+(Math.random()*0xFFFFFF<<0).toString(16),
          segments: [],
          isCompleted: true,
        }

        const image = data.images[data.images.findIndex(image => image.id === annotation.image_id)]

        const pairs = annotation.segmentation[0].reduce((result, value, index, sourceArray) => index % 2 === 0 ? [...result, sourceArray.slice(index, index + 2)] : result, []);

        pairs.forEach(segmentationCoordinate =>
        {
          target.segments.push({
            instanceId: AbstractStoreManagerBuilder.createInstanceId(),
            type: annotation.caption,
            colorCode: target.colorCode,
            left: segmentationCoordinate[0] * 100 / image.width,
            top: segmentationCoordinate[1] * 100 / image.height
          })
        })

        targets.push(target);
      }
    })

    targets.map(target => SegmentedImagesStoreManager.addTarget(finalEntry.instanceId, target))

    // also add the segment types to the menu
    SegmentationTargetTypeParamsStoreManager.addTypesFromTargets(targets);
  }

  public addTarget(annotatableImageInstanceId: string, target: SSegmentedTarget)
  {
    const entry = this._store.byInstanceId(annotatableImageInstanceId);

    if (entry) {
      this.store.addTarget({
        segmentedImage: entry,
        target: target,
      })
    }
  }

  public addTargetForCurrent(target: SSegmentedTarget)
  {
    this.store.addTarget({
      target: target,
      segmentedImage: this.getCheckedCurrentSegmentedImage()
    })
  }

  public createEmptyTargetForCurrent(type: string, colorCode: string, makeCurrent: boolean)
  {
    if (makeCurrent)
      this.store.resetIsCurrentTargetForCurrentSegmentedImage();

    this.store.addTarget({
      target: {
        segments: [],
        instanceId: AbstractStoreManagerBuilder.createInstanceId(),
        type: type,
        colorCode: colorCode,
        isCurrent: makeCurrent,
      },
      segmentedImage: this.getCheckedCurrentSegmentedImage()
    })
  }

  public attachEmptyTarget(type: string, colorCode: string)
  {
    const spatial2DBoundingBoxManager = new SegmentedTargetManager();

    const result = spatial2DBoundingBoxManager.tryAddNew(type, colorCode);

    this.setCurrentSegmentedTarget(result);
  }

  // ------------------------------
  //  Set
  // ------------------------------

  public setCurrentSegmentedImageFromImageData(imageData: SImageData)
  {
    this._store.setCurrentSegmentedImage(this._store.entries.filter(entry => entry.image.instanceId === imageData.instanceId)[0].instanceId);
  }

  public setSegmentsForCurrentTarget(segments: S2DSegment[])
  {
    this._store.setSegmentsForCurrentTarget(segments);
  }

  public setSegments(segments: S2DSegment[])
  {
    const annotatableImageInstanceId = this.getCheckedCurrentSegmentedImage().instanceId;
    const targetId = this.getCheckedCurrentSegmentedTarget().instanceId;

    const annotatableImage = this.getByInstanceId(annotatableImageInstanceId);

    const target = annotatableImage.targets.filter(target => target.instanceId === targetId);

    if (target.length === 1)
    {
      target[0].segments = segments;

      this.setByInstanceId(annotatableImageInstanceId, annotatableImage);
    }
    else
    {
      throw "Can't add segments to target of instanceId "+ targetId +" : bounding box doesn't exist for annotatableImage of instanceId "+ annotatableImageInstanceId;
    }
  }

  // ------------------------------
  //  Copy
  // ------------------------------

  public copyPreviousImageAnnotations = () =>
  {
    const previousImage = this._store.previousSegmentedImage;
    const currentImage = this._store.currentSegmentedImage;

    if (previousImage && currentImage && previousImage.targets.length)
    {
      this._store.removeCurrentImageTargets();

      previousImage.targets.map(target =>
      {
        const newTarget: SSegmentedTarget = {
          instanceId: AbstractStoreManagerBuilder.createInstanceId(),
          type: target.type,
          colorCode: target.colorCode,
          isCompleted: target.isCompleted,
          isCurrent: target.isCurrent,
          nextInsertableSegmentIndex: target.nextInsertableSegmentIndex,
          segments: target.segments.map(segment => {
            return {
              instanceId: AbstractStoreManagerBuilder.createInstanceId(),
              type: segment.type,
              isCurrent: segment.isCurrent,
              left: segment.left,
              top: segment.top,
              colorCode: segment.colorCode
            }
          })
        };

        this.addTarget(currentImage.instanceId, newTarget);
      })
    }
    else
    {
      alert("No previous image with targets to copy")
    }
  }

  // ------------------------------
  //  Delete
  // ------------------------------

  public removeTarget(target: SSegmentedTarget)
  {
    this._store.resetCurrentSegmentedImage();

    this._store.removeTarget(target.instanceId);
  }

  public removeSegmentOfInstanceIdFromCurrentTarget(instanceId: string)
  {
    this.store.removeSegmentFromCurrentTarget(instanceId);
  }

  // ------------------------------
  //  Communications with the store
  // ------------------------------

  public getByImageInstanceId(imageInstanceId: string)
  {
    const compatibleImages = this._store.entries.filter(annotatableImageData => annotatableImageData.image.instanceId === imageInstanceId);

    if (compatibleImages.length !== 1)
      throw "Logical Error : No or multiple entries for the imageInstanceId : " + imageInstanceId;

    return compatibleImages[0];
  }

  protected getByInstanceId(instanceId: string)
  {
    return this._store.byInstanceId(instanceId, true) as SSegmentedImage
  }

  protected setByInstanceId(instanceId: string, annotatableImage: SSegmentedImage)
  {
    this._store.setByInstanceId({
      entry: annotatableImage,
      instanceId: instanceId
    });
  }
}

export const SegmentedImagesStoreManager = new SegmentedImagesStoreManagerBuilder();