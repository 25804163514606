import {PageContentLayoutPositioning} from "@/lib/components/layout/page/enum/PageContentLayoutPositioning";
import {PageContentLayoutSpaceTaken} from "@/lib/components/layout/page/enum/PageContentLayoutSpaceTaken";

const PageContentLayoutSpaceTakenPaddings = {
    [PageContentLayoutSpaceTaken.ALL]: {
        [PageContentLayoutPositioning.CENTER]: {
            "paddingLeft": "0",
            "paddingRight": "0",
            "paddingBottom": "0",
            "paddingTop": "0",
        },
        [PageContentLayoutPositioning.FLEX_START]: {
            "paddingLeft": "0",
            "paddingRight": "0",
            "paddingBottom": "0",
            "paddingTop": "0",
        },
        [PageContentLayoutPositioning.FLEX_END]: {
            "paddingLeft": "0",
            "paddingRight": "0",
            "paddingBottom": "0",
            "paddingTop": "0",
        },
    },
    [PageContentLayoutSpaceTaken.MODERATELY]: {
        [PageContentLayoutPositioning.CENTER]: {
            "paddingLeft": "calc(20% - 290px)",
            "paddingRight": "calc(20% - 290px)",
            "paddingBottom": "calc(20% - 290px)",
            "paddingTop": "calc(20% - 290px)",
        },
        [PageContentLayoutPositioning.FLEX_START]: {
            "paddingLeft": "calc(20% - 290px)",
            "paddingRight": "calc(20% - 290px)",
            "paddingBottom": "calc(20% - 290px)",
            "paddingTop": "0",
        },
        [PageContentLayoutPositioning.FLEX_END]: {
            "paddingLeft": "calc(20% - 290px)",
            "paddingRight": "calc(20% - 290px)",
            "paddingBottom": "0",
            "paddingTop": "calc(20% - 290px)",
        },
    }
}

export default Object.freeze(PageContentLayoutSpaceTakenPaddings);