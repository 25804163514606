/**
 * Use this class to get a loaded file's content and other things
 */
class FileHelper
{
    public async readAsText(file: File)
    {
        const fileText = await new Promise(resolve => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result || "");
            };
            reader.readAsText(file);
        });

        if (typeof (fileText) == "string") {
            return fileText;
        } else {
            console.error(fileText);
            throw "The given file couldn't be read as text. See console log for the response's content."
        }
    }
}

export default new FileHelper();