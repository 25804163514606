import {Module, Mutation} from 'vuex-module-decorators'
import {SPopUpState} from "@/lib/store/model/popup/SPopupState";
import {AbstractStore} from "@/lib/store/holder/abstract/AbstractStore";

@Module({
  name: "PopUpsStore",
  namespaced: true,
  stateFactory: true
})

export class PopUpsStore<D extends SPopUpState> extends AbstractStore<D>
{
  get popUpsStates()
  {
    return this.entries;
  }

  get popUpOfInstanceId()
  {
    return this.byInstanceId;
  }

  @Mutation
  setPopUpStateVisibility({ visibility, instanceId }: { visibility: boolean; instanceId: string})
  {
    this._entries.filter(popUpState => {
      if (popUpState.instanceId === instanceId)
        popUpState.isVisible = visibility;
    })
  }

  public populate()
  {
    // Get wrapped parent module to access getters
    const base: any = Module({})(AbstractStore);
    // Copy parent getters to child
    Object.assign(PopUpsStore.getters, base.getters);
    Object.assign(PopUpsStore.mutations, base.mutations);
  }
}