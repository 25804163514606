import { createApp } from 'vue'
import router from './router';
import store from './lib/store'
import App from './App.vue'
import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import Vue3DraggableResizable from 'vue3-draggable-resizable'
//default styles
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'

const app = createApp(App)
    .use(router)
    .use(store)
    .use(Vue3ColorPicker)
    .use(Vue3DraggableResizable)
;

app.mount("#app");