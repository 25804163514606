import {Module, Mutation} from 'vuex-module-decorators'
import {AbstractStore} from "@/lib/store/holder/abstract/AbstractStore";
import {S2DSegment} from "@/store/Spatial2DAnnotation/model/S2DSegment";
import {SSegmentedImage} from "@/store/Spatial2DAnnotation/model/SSegmentedImage";
import {SSegmentedTarget} from "@/store/Spatial2DAnnotation/model/SSegmentedTarget";

export enum SWITCH_DIRECTION {
  NEXT = "NEXT",
  PREVIOUS = "PREVIOUS",
}

@Module({
  name: "SegmentedImagesStore",
  namespaced: true,
  stateFactory: true
})

export class SegmentedImagesStore<D extends SSegmentedImage> extends AbstractStore<D>
{
  // ------------------------------
  //  General
  // ------------------------------

  get annotated()
  {
    return this._entries.filter(entry => entry.targets.length);
  }

  // ------------------------------
  //  Currently selected
  // ------------------------------

  // ------------
  //  Image
  // ------------

  get currentSegmentedImage()
  {
    const currentSegmentedImage = this._entries.filter(entry => entry.isCurrent);

    return currentSegmentedImage.length ? currentSegmentedImage[0] : undefined;
  }

  get previousSegmentedImage()
  {
    const previousSegmentedImageIndex = this._entries.findIndex(entry => entry.isCurrent);

    return previousSegmentedImageIndex === 0 ? undefined : this._entries[previousSegmentedImageIndex - 1];
  }

  get hasPreviousSegmentedImageTargets()
  {
    const previousSegmentedImageIndex = this._entries.findIndex(entry => entry.isCurrent);

    return !!(previousSegmentedImageIndex > 0 && this._entries[previousSegmentedImageIndex - 1].targets.length);
  }

  @Mutation
  setCurrentSegmentedImage(annotatableImageInstanceId: string)
  {
    this._entries.map(entry => entry.isCurrent = entry.instanceId === annotatableImageInstanceId);
  }

  @Mutation
  resetCurrentSegmentedImage()
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => {
          entry.targets = [];
        }
    );
  }

  // ------------
  //  Target
  // ------------

  get currentSegmentedTarget()
  {
    const currentImage = this._entries.filter(entry => entry.isCurrent);
    const currentTargets =  currentImage && currentImage.length ? currentImage[0].targets.filter(target => target.isCurrent) : undefined;

    return currentTargets && currentTargets.length ? currentTargets[0] : undefined;
  }

  @Mutation
  resetIsCurrentTargetForCurrentSegmentedImage()
  {
    this._entries.filter(entry => entry.isCurrent).map(entry => entry.targets.map(target => target.isCurrent = false));
  }

  @Mutation
  setTargetIsCurrent(targetInstanceId: string, isCurrent: boolean)
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => entry.targets.filter(target => target.instanceId === targetInstanceId).map(target => target.isCurrent = isCurrent)
    );
  }

  @Mutation
  setCurrentTargetIsCompleted(isCompleted: boolean, unCurrent: boolean = true)
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => entry.targets.filter(target => target.isCurrent).map(target => {
          target.isCompleted = isCompleted;
          if (unCurrent)
            target.isCurrent = false;
        })
    );
  }

  @Mutation
  setFirstUncompletedTargetOfTypeIsCurrent(targetType: string)
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => entry.targets.filter(target => target.type === targetType && !target.isCompleted).map(target => target.isCurrent = true)
    );
  }

  // ------------
  //  Segments
  // ------------

  get nextInsertableSegmentIndexForCurrentTarget ()
  {
    let index;

    this._entries.filter(entry => entry.isCurrent).map(entry => entry.targets.filter(target => target.isCurrent).map(currentTarget =>
    {
      index = currentTarget.nextInsertableSegmentIndex;
    }));

    return index;
  }

  get nextSegmentsAroundNextInsertableSegmentIndexForCurrentTarget ()
  {
    const nextSegmentsAround: S2DSegment[] = [];

    this._entries.filter(entry => entry.isCurrent).map(entry => entry.targets.filter(target => target.isCurrent).map(currentTarget =>
    {
      if (
          typeof currentTarget.nextInsertableSegmentIndex === "undefined"
          || currentTarget.nextInsertableSegmentIndex === currentTarget.segments.length -1
      )
      {
        if (currentTarget.isCompleted)
          nextSegmentsAround.push(currentTarget.segments[0])

        nextSegmentsAround.push(currentTarget.segments[currentTarget.segments.length -1])
      }
      else
      {
        nextSegmentsAround.push(currentTarget.segments[currentTarget.nextInsertableSegmentIndex])
        nextSegmentsAround.push(currentTarget.segments[currentTarget.nextInsertableSegmentIndex + 1])
      }
    }))

    return nextSegmentsAround;
  }

  @Mutation
  setNextInsertableSegmentIndexForCurrentTarget(index: number)
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => entry.targets.filter(target => target.isCurrent).map(target => {
          target.nextInsertableSegmentIndex = index;
        })
    );
  }

  @Mutation
  incrementNextInsertableSegmentIndexForCurrentTarget()
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => entry.targets.filter(target => target.isCurrent).map(target => {
          target.nextInsertableSegmentIndex = typeof target.nextInsertableSegmentIndex === 'undefined' ? target.segments.length - 1 : (target.nextInsertableSegmentIndex + 1);
        })
    );
  }

  @Mutation
  setSegmentsForCurrentTarget(segments: S2DSegment[])
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => entry.targets.filter(target => target.isCurrent).map(target => {
          target.segments = segments;
        })
    );
  }

  @Mutation
  switchToNextOrPreviousNextInsertableSegmentIndexForCurrentTarget({direction}: {direction: SWITCH_DIRECTION})
  {
    const thisImageIndex = this._entries.findIndex(entry => entry.isCurrent);

    if (thisImageIndex === -1)
      return;

    const thisTargetIndex = this._entries[thisImageIndex].targets.findIndex(target => target.isCurrent);

    if (thisTargetIndex === -1)
      return;

    let selectedIndex = this._entries[thisImageIndex].targets[thisTargetIndex].nextInsertableSegmentIndex;

    if (typeof selectedIndex === "undefined")
      selectedIndex = this._entries[thisImageIndex].targets[thisTargetIndex].segments.length - 1;

    const testedEntryIndex = selectedIndex >= 0 ? selectedIndex : (this._entries[thisImageIndex].targets.length - 1);

    let nextOneIndex = testedEntryIndex + (direction === SWITCH_DIRECTION.NEXT ? 1 : -1);

    if (nextOneIndex < 0)
      nextOneIndex = this._entries[thisImageIndex].targets[thisTargetIndex].segments.length - 1;

    if (direction === SWITCH_DIRECTION.NEXT)
    {
      if (nextOneIndex >= this._entries[thisImageIndex].targets[thisTargetIndex].segments.length)
        nextOneIndex = 0;
    }
    else
    {
      if (nextOneIndex < 0)
        nextOneIndex = this._entries[thisImageIndex].targets[thisTargetIndex].segments.length - 1;
    }

    this._entries[thisImageIndex].targets[thisTargetIndex].nextInsertableSegmentIndex = nextOneIndex;
  }

  @Mutation
  removeSegmentFromCurrentTarget(segmentInstanceId: string)
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => entry.targets.filter(target => target.isCurrent).map(target => {
          const key = target.segments.findIndex(segment => segment.instanceId === segmentInstanceId);
          target.segments = target.segments.filter(segment => segment.instanceId !== segmentInstanceId);
          target.nextInsertableSegmentIndex = key - 1;
        })
    );
  }

  @Mutation
  removeSegmentFromTarget(targetInstanceId: string, segmentInstanceId: string)
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => entry.targets.filter(target => target.instanceId === targetInstanceId).map(target => {
          target.segments = target.segments.filter(segment => segment.instanceId !== segmentInstanceId);
        })
    );
  }

  // ------------------------------
  //  Targets
  // ------------------------------

  get targetByInstanceId()
  {
    const _this = this;

    return function (segmentedImageInstanceId: string, targetInstanceId: string): SSegmentedTarget
    {
      const entry = _this._entries.filter(entry => entry.instanceId === segmentedImageInstanceId);
      const target = entry.length ? entry[0].targets.filter(target => target.instanceId === targetInstanceId) : [];

      const result = target ? target[0] : undefined;

      if (typeof result === "undefined")
        throw "Fatal error : Can't find boundingBox of instanceId "+ targetInstanceId +" for segmentedImage of instanceId "+ segmentedImageInstanceId;

      return result;
    }
  }

  @Mutation
  addTarget({segmentedImage, target}: {segmentedImage: SSegmentedImage, target: SSegmentedTarget})
  {
    segmentedImage.targets.push(target);
  }

  @Mutation
  setSegmentsForTarget(targetInstanceId: string, segments: S2DSegment[])
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => entry.targets.filter(target => target.instanceId === targetInstanceId).map(target => {
          target.segments = segments;
        })
    );
  }

  @Mutation
  switchToNextOrPreviousTarget({direction, allowIsComplete}: {direction: SWITCH_DIRECTION, allowIsComplete: boolean})
  {
    const thisImageIndex = this._entries.findIndex(entry => entry.isCurrent);

    if (thisImageIndex === -1)
      return;

    const selectedIndex = this._entries[thisImageIndex].targets.findIndex(target => target.isCurrent);

    const trySwitch = (testedEntryIndex: number, triedTimes: number = 1) =>
    {
      let nextOneIndex = testedEntryIndex + (direction === SWITCH_DIRECTION.NEXT ? 1 : -1);

      if (direction === SWITCH_DIRECTION.NEXT)
      {
        if (nextOneIndex >= this._entries[thisImageIndex].targets.length)
          nextOneIndex = -1;
      }
      else
      {
        if (nextOneIndex === -2)
          nextOneIndex = this._entries[thisImageIndex].targets.length - 1;
      }

      if (nextOneIndex === -1 || triedTimes > this._entries[thisImageIndex].targets.length) {
        // if all entries have been tested, abort.
        this._entries[thisImageIndex].targets.forEach(target => {
          target.isCurrent = false;
        });
        return;
      }

      if (!allowIsComplete && this._entries[thisImageIndex].targets[nextOneIndex].isCompleted)
      {
        trySwitch(nextOneIndex, ++triedTimes)
      }
      else
      {
        this._entries[thisImageIndex].targets.forEach(target => {
          target.isCurrent = false;
        });

        this._entries[thisImageIndex].targets[nextOneIndex].isCurrent = true;
      }
    }

    trySwitch(selectedIndex)
  }

  @Mutation
  removeTarget(targetInstanceId: string)
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => {
          entry.targets = entry.targets.filter(target => target.instanceId !== targetInstanceId)
        }
    );
  }

  @Mutation
  removeCurrentImageTargets()
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => {
          entry.targets = []
        }
    );
  }

  public populate()
  {
    // Get wrapped parent module to access getters
    const base: any = Module({})(AbstractStore);
    // Copy parent getters to child
    Object.assign(SegmentedImagesStore.getters, base.getters);
    Object.assign(SegmentedImagesStore.mutations, base.mutations);
  }
}